<template>
  <div class="pages p20">
    <div class="title-btn-block">
      <div class="title-block">{{ $t("message.additional_work") }}</div>
      <div>
        <router-link
          v-if="staff_child || staffId == 12396 || staffId == 11872"
          :to="{ name: 'additionalWorkAdd', params: { id: staffId } }"
        >
          <div
            class="my-btn mr-2 el-button"
            style="background:#00CC00; color:#fff; margin-right: 10px;"
          >
            Создать
          </div>
        </router-link>
        <router-link :to="{ name: 'Home' }">
          <div class="my-btn cr1 el-button">
            <i class="el-icon-d-arrow-left"></i> {{ $t("message.back") }}
          </div>
        </router-link>
      </div>
    </div>

    <div class="content-block">
      <div class="datetime-end bg p20 mb20">
        <el-divider content-position="left"
          >{{ $t("message.additional_work") }}
        </el-divider>
        <div class="scrol-table crmhome__scroll my-table">
          <table>
            <thead>
              <tr>
                <th>{{ $t("message.n") }}</th>
                <th>{{ $t("message.work_type") }}</th>
                <th>{{ $t("message.reason") }}</th>
                <th>{{ $t("message.user") }}</th>
                <th>{{ $t("message.time") }}</th>
                <th>{{ $t("message.count") }}</th>
                <th>{{ $t("message.status") }}</th>
                <th>{{ $t("message.confirm") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(work, index) in lists" :key="work.id">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ work.performance_type == 1 ? "To'lovli" : "To'lovsiz" }}
                </td>
                <td>{{ work.reason.name }}</td>
                <td>
                  {{ work.staff.name }} {{ work.staff.last_name }}
                  {{ work.staff.middle_name }}
                </td>
                <td>
                  {{ work.work_date_time_from }} - {{ work.work_date_time }}
                </td>
                <td>{{ work.count }}</td>
                <td>
                  <el-button
                    @click="showStaffList(work.id)"
                    type="success"
                    size="medium"
                    class="remove-button"
                    >{{ $t("message.details") }}</el-button
                  >
                </td>
                <td>
                  <el-button
                    v-if="work.status == 'waiting'"
                    type="warning"
                    size="medium"
                    class="remove-button"
                    >{{ $t("message.waiting") }}</el-button
                  >
                  <el-button
                    v-if="work.status == 'accept'"
                    type="success"
                    size="medium"
                    class="remove-button"
                    >{{ $t("message.received") }}</el-button
                  >
                  <el-button
                    v-if="work.status == 'deny'"
                    type="danger"
                    size="medium"
                    class="remove-button"
                    >{{ $t("message.denied") }}</el-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <el-drawer
      title="I'm outer Drawer"
      :visible.sync="showUseerModal"
      size="30%"
      :with-header="false"
      ref="show-users"
      @opened="drawerOpened('show-users')"
    >
      <div>
        <show-users
          :staff="selected"
          @closeUserModal="closeUserModal"
          :child="child"
          ref="show-users"
        ></show-users>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { getItem } from "@/utils/storage";
import showUsers from "./components/show-users.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      salarys: null,
      complaints: [],
      child: {},
      filterForm: {},
      selected: null,
      showUseerModal: false,
      staffId: getItem("userId"),
      advance_salary: null
    };
  },
  components: {
    showUsers
  },
  computed: {
    ...mapGetters({
      lists: "additionalWork/works",
      staff_child: "additionalWork/staff_child"
    })
  },
  mounted() {
    this.getTable();
  },

  methods: {
    ...mapActions({
      updateList: "additionalWork/works"
    }),

    getTable() {
      this.updateList(getItem("userId"))
        .then(res => {
          // this.assents = res.take_assent
        })
        .catch(err => {
          this.$alert(err);
        });
    },
    closeUserModal(val) {
      this.showUseerModal = val;
    },
    showStaffList(work) {
      this.showUseerModal = true;
      this.child.work_id = work;
      this.child.staff_id = getItem("user_id");
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    }
  }
};
</script>
